.presContainer {
    /*width: 700px;*/
    height: 393px;
    
}

.vosstatsPage {   
    flex-grow: 1; /* Permet à la colonne principale de s'étendre pour remplir l'espace disponible */
    max-width: 800px;
    /*margin-left:200px;*/
}

.vosstatsPage h1 {
    margin: 20px 10px 4px 0px;
    /*background-color: #eaf4f4;*/
    /*background-color:rgb(120, 120, 120);*/
    background-color: #6b9080;
    /*color: #eaf4f4;*/
    color:white;
    /*width: fit-content;*/
    width: fit-content;
    
    
    border-bottom: 4px solid lightgrey;
    border-radius:10px;
    
    padding: 10px;
    
    font-weight:bold;
}

.vosstatsPage h2 {
    margin: 20px 10px 4px 0px;
    /*background-color: #eaf4f4;*/
    /*background-color:rgb(120, 120, 120);*/
    background-color: #cce3de ;
    /*color: #eaf4f4;*/
    /*width: fit-content;*/
    width: fit-content;
    
    
    border-bottom: 4px solid lightgrey;
    border-radius:10px;
    
    padding: 5px 10px;
    
    font-weight:bold;
}


.vosstatsPage a {
    color: #6b9080;
    /*color:red;*/
    font-weight: bold;
}
.vosstatsPage a:hover {
    color:black;
}

.vosstatsPage p {
    margin-left:10px;
    text-align: justify;

}



.mc_embed_signup{
    background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;
}