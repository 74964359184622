.loginPage {
    margin:100px;
    display:flex;

}

.form-group {
    padding:2px;
    font-family: Arial, Helvetica, sans-serif;
    width: 300px;
    font-weight: bold;
}

.form-control {
    width:250px;
}

button {
    padding:2px;
    margin:4px 0;
    width:200px;
}
