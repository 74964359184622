.loans-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  margin: 20px;
  max-width: 90%;
}

.loans-table th,
.loans-table td {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.loans-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.loans-table tr:hover {
  background-color: coral;
}

.loans-table thead th {
  background-color: #aaa;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.globalInfo {
  background-color: #6b9080ff;
  height: 100px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
  padding: 5px;
  border-radius: 10px;
  min-width:80%;
  width: fit-content;
}

.globalInfoPlatform {
  display:flex;
  align-items: flex-start;
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante si nécessaire */
  width: 100%;
  /*border: 1px dotted black;*/
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
  padding: 5px;
  border-radius: 10px;

}

.globalInfoPlatform .card-big {
  background-color: #cce3de;
  height: 150px;
  /*width: max-content;*/
  
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  font-size:small;
  border-radius: 10px;
  border-bottom: 4px solid lightgrey;
  
}

.globalInfoPlatform .card-big h2 {
  font-size:large;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: bold;
}


.globalInfo .card {
  background-color: #cce3de;
  height: 90px;
  width: max-content;
  
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  float: left;
  border-radius: 10px;
}


.card-buttons {
  margin-left:30px;
  /*border: 1px solid red;*/
}

.card h2 {
  font-size: larger;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: bold;
}

.card h3 {
  font-size: medium;
  margin: 0px;
  margin-left: 3px;
  font-weight: normal;
}

.card h4 {
  font-size: small;
  margin: 1px;
  margin-left: 10px;
  font-weight: normal;
}

.card ul {
  /*spacing: 0px;*/
  list-style: none;
  margin: 5px;
}

.statsGlobalMenu {
  width: fit-content;
  min-width:80%;
  background-color: #6b9080;

  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 10px;
}

.statsGlobalMenu .statsSubMenu {
  background-color: #eaf4f4;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  width: max-content;
  float: left;
}

.statsGlobalMenu .statsSubMenu:hover {
  background-color: #f6fff8;
  cursor: pointer;
  font-weight: lighter;
}

.chart-container {
  width: 540px; /* Remplacez par la largeur souhaitée */
  border: 1px solid rgb(186, 208, 16);
  border:1px dotted;
  padding:3px;
  border-radius: 10px;
}

.grid {
  display:flex;
  align-items: flex-start;
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante si nécessaire */

  /*width: 80%;*/
  height: auto;
  margin: 0 30px;
  /*border: 1px solid rgb(48, 207, 48);*/
  /*display: grid;*/
  grid-template-columns: repeat(auto-fill, 570px);
  /*justify-content: center;*/
  /*border:1px solid red;*/

}
.grid-item {
  /*border: 1px solid blue;*/
  width:fit-content;
  height: fit-content;
  float: left;
  margin: 10px;

  
}

.grid-infos {
  height: auto;
  margin: 0 30px;
 /* border: 1px solid rgb(48, 207, 48);*/
  display:grid;
  grid-template-columns: repeat(auto-fill, 350px);

}
.grid-infos-item {
  width:fit-content;
  border: 1px dotted;
  border-radius: 10px;
  /*height: fit-content;*/
  height:auto;
  width:320px;
  margin: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 10px;
  font-weight: bold;  
  background-color: #f6fff8;
  text-align: left;
}


.sectionTitle  {
  margin: 20px 10px 4px 10px;
  /*background-color: #eaf4f4;*/
  /*background-color:rgb(120, 120, 120);*/
  
  background-color: #cce3de ;

  /*color: #eaf4f4;*/
  /*width: fit-content;*/
  min-width:80%;
  text-align: left;
  
  border-bottom: 4px solid lightgrey;
  border-radius:10px;
  
  padding: 10px;
  font-size:large;
  font-weight:bold;
}

.customStatsSection {
  margin:10px;
  
}

.customStatsFiltersMenu {
  height:50px;

}

.customStatsFiltersMenuItem {
  
  float:left;
  margin:2px 0px;
  padding:0px 10px;
}

.customStatsFiltersMenuItem h3 {
  margin: 0;
}

.customStatsResultsCounter {
  font-size: small;
  font-style: italic;
  margin-left:20px;
}

.projectsListOnOff {
  
}

.tabButton {
  background-color: #6B9080;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
  text-decoration: none;

}

.tabButton:hover {
  background-color: #CCE3DE;
  color: #6B9080;
}

.statsPlatform {
  color: white;
  
  border-bottom: 4px solid lightgrey;
  border-radius:10px;
  
  padding: 10px;
  font-size:x-large;
  font-weight:bold;

  margin-left:10px;
  margin-right:10px;

  
  
  /*background-color: rgb(91, 91, 91);*/
  /*background-color:rgb(120, 120, 120);*/
  background-color: #6b9080;
}

.promoLBP {
  border:1px solid grey;
  width:200px; 
  text-align: center;
  
}

.promoLBP-titre {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  border: 1px solid;
  text-align: center;  
  background-color: grey;
  color: rgb(255, 255, 255);
  padding:5px;
}

.promoLBP-cover img {
  height:140px;
}

.promoLBP-nom {
  font-size: 28px;
  font-weight: bold;
}

.promoLBP-montant, .promoLBP-taux, .promoLBP-duree {
  font-weight: bold;
  margin:2px;
  font-size:large;
}

.promoLBP-compteur {
  color:rgb(233, 63, 63);
  font-style: italic;
  font-size:22px;
  margin: 10px 0px;
}

.promoLBP-parrainage {
  background-color: #6B9080;
  padding:2px;
  color:white;
  text-align: center;
}



.promoLBP-parrainage a:hover {
  color: black;
}


.promoLBP-bouton-parrainage {
  font-size: 24px;
  font-weight: bold;
  border: 1px solid;
  text-align: center;  
  background-color: grey;
  color: white;
  padding:5px;
}

.promoLBP-bouton-parrainage a, a:visited {
  color: white;
  text-decoration: none;
}

.promoLBP-bouton-parrainage a:hover {
  color: black;
}


.promoLBP-titre-parrainage {
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin-bottom:8px;
}

/* Conteneur pour les colonnes */
.page-container {
  display: flex;
  /*justify-content:center;*/
  align-items: flex-start;
  height: 100vh;
}

/* Colonne principale */
.main-content {
  flex-grow: 1; /* Permet à la colonne principale de s'étendre pour remplir l'espace disponible */
  margin-right: 20px; /* Ajoute une marge à droite pour séparer les colonnes */
  max-width: 1300px;
}


/* Colonne de droite */
.right-sidebar {
  margin-top:400px;  
  margin-right:10px;
  display: none; /* par défaut, masquer l'élément */

}

@media only screen and (min-width: 768px) {
  /* Styles pour les écrans de largeur 768px et plus */
  .right-sidebar {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .page-container {
    justify-content: center;
  }
}