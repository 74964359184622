.aboutPage {
    /*
    max-width: 800px;
    margin:10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-127%);
    */
    flex-grow: 1; /* Permet à la colonne principale de s'étendre pour remplir l'espace disponible */
    margin-right: 20px; /* Ajoute une marge à droite pour séparer les colonnes */
    max-width: 800px;
}

.aboutPage p {
    margin-left:10px;
    text-align: justify;
}

.aboutPage h1 {

    border: 1px solid lightgrey;
    border-bottom: 4px solid lightgrey;
    border-radius:10px;
    
    padding: 5px 10px;
    
    font-weight:bold;

    background-color: #6b9080;
    color:white;
}

.aboutPage h2 {
    margin: 20px 10px 4px 0px;
    /*background-color: #eaf4f4;*/
    /*background-color:rgb(120, 120, 120);*/
    background-color: #cce3de ;
    /*color: #eaf4f4;*/
    /*width: fit-content;*/
    width: fit-content;
    text-align: left;
    
    border-bottom: 4px solid lightgrey;
    border-radius:10px;
    
    padding: 5px 10px;
    
    font-weight:bold;
}

.aboutPage h3 {
    margin-left: 5px;
}