.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px;
}

table a:visited {
  color:black;
}

table a {
  color:black;
}

th,
td {
  border: 1px solid black;
  text-align: center;
  padding: 8px;
}

thead th {
  background-color: #f2f2f2;
}

.ImmoZaik {
  width: 100%;
  border:1px solid black;
  border: none;
  max-width: none;

}

nav {
  width:100%;
}

nav ul {
  display: flex;
  margin: 0 -10px;
}

nav li {
  margin: 0 10px;
  
}

.push-right {
  margin-left: auto;
  font-style: italic;
}


.globalMenu {
  display: flex;
/*  justify-content: space-between;*/
  flex-grow: 1;
  /*align-items: flex-start;*/
  /*flex-wrap: wrap; */
  border: 1px solid;
  
  /*
  width:100%;
  */
  
  
  background-color: #6B9080;
  border-radius: 10px;
  margin-bottom: 10px;
  color: white;
}

.globalMenuLogo {
  width: 150px;
  height: 30px;
  float:left;
  display:flex;
  align-items:center;
  float:left;
  /*padding:10px;*/
  font-weight: bold;
  font-size: x-large;
}

.globalMenusubLogo {
  margin-left:10px;
  font-size:small;
}

.globalMenuItem {
  /*flex-grow: 1; */
  
  align-items:center;
  display:flex;
/*  justify-content:start;*/
  /*float:left;*/
  margin:5px 5px;
  
}


.globalMenuItem a {
  margin:10px 10px;
  text-decoration: none;
  color: white;
  border:1px dotted;
  border-radius: 10px;
  padding:5px;
  font-weight: bold;
}

.globalMenuItem a:visited {
  color: white;
}

.globalMenuItem a:hover {
  background-color: white;
  color: #6B9080;
  
  border-radius: 10px;
}

.globalMenuConn {
  
  /*
  height: 30px;
  width:fit-content;
  align-items:center;
  display:flex;
  justify-content:center;
  float:right;
  padding:10px;
  */
  
}
.globalMenuConn a {
  padding:2px;
  text-decoration: none;
}
.globalMenuConn a:visited {
  color: white;
}
.globalMenuConn a:hover {
  background-color: white;
  color: #6B9080;
  font-weight: bold;
}

.globalMenuConn .push-right {
  margin-left: auto;

}

.username {
  margin-right:20px;
  font-style: italic;
}


.menuItems {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuRight {
  margin-left: auto;
}

